import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Observable, timer } from 'rxjs';
import { concatMap, finalize, take, takeUntil } from 'rxjs/operators';

import { SoundService } from '../../../../core/services/sound.service';

import { ChallengeQuestion } from '../../../models/entities/challenge-question.entity';
import { ChallengeSession } from '../../../models/entities/challenge-session.entity';
import { QuestionAnswerMethod } from '../../../models/enums/question.enums';
import { Correction } from '../../../models/entities/correction.entity';
import { BaseComponent } from '../../../components/inherited/base/base.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpChallengeSessionService } from '../../../../core/services/http/http-challenge-session.service';
import {
  AnswerManyDefinedQuestionPayload,
  AnswerManyUndefinedQuestionPayload,
  AnswerMatchingAreaQuestionPayload,
  AnswerMatchQuestionPayload,
  AnswerOneOrTCSQuestionPayload,
} from '../../../models/payloads/session.payload';
import { ErrorService } from '../../../../core/services/error.service';
import { supportedScroll } from '../../../utils/scroll';
import {
  AnswerToSessionQuestionResult,
  SessionQuestionResult,
} from '../../../models/api-results/session-question.api-result';
import { Question } from '../../../models/entities/question.entity';
import { GoogleAnalyticsService } from '../../../../core/services/google-analytics.service';
import * as AuthSelectors from '../../../../core/state/auth-state/auth.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-question-challenge',
  templateUrl: './question-challenge.component.html',
  styleUrls: ['./question-challenge.component.scss'],
})
export class QuestionChallengeComponent extends BaseComponent implements OnInit {
  @Input() challengeSession: ChallengeSession;
  @Input() currentChallengeQuestion: ChallengeQuestion;

  @Input() remainingSeconds: number;
  @Input() percentTimeElapsed: number;

  @Output() goToNextQuestionEmitter = new EventEmitter();

  isAnswering = false;

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer') canvasContainer: ElementRef<HTMLDivElement>;
  @ViewChild('imageZAP') imageZAP: ElementRef<HTMLImageElement>;

  canvasContext: CanvasRenderingContext2D;

  grade: number;
  maxGrade: number;
  isCorrection = false;

  animationState = {
    questionTop: false,
    questionContent: false,
    questionText: false,
    questionNumber: false,
    questionAnswers: false,
    questionQroc: false,
    questionZap: false,
    questionButton: false,
  };

  isPremiumPlus$ = this.store.select(AuthSelectors.isPremiumPlus);

  QuestionAnswerMethod = QuestionAnswerMethod;

  constructor(
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService,
    private errorService: ErrorService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private soundService: SoundService,
    private httpChallengeSessionService: HttpChallengeSessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentChallengeQuestion.question.updateCanvasEmitter
      .pipe(takeUntil(this.alive$))
      .subscribe(() => {
        this.drawPoint();
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeCanvas();
    if (this.isCorrection) {
      this.drawCorrection();
    } else {
      this.drawPoint();
    }
  }

  selectOption(correction: Correction) {
    if (!this.isCorrection) {
      correction.isSelected = !correction.isSelected;

      if (this.currentChallengeQuestion.question.answerMethod === QuestionAnswerMethod.ONE) {
        this.currentChallengeQuestion.question.corrections.forEach((correctionToUnselect) => {
          if (correction.id !== correctionToUnselect.id) {
            correctionToUnselect.isSelected = false;
          }
        });
      }
    }
  }

  answerToQuestion() {
    if (!this.isAnswering && !this.isCorrection) {
      let answerRequest: Observable<AnswerToSessionQuestionResult>;
      switch (this.currentChallengeQuestion.question.answerMethod) {
        case QuestionAnswerMethod.MATCH:
          if (!this.currentChallengeQuestion.question.answerMatch) {
            this.toastr.warning(this.translate.instant('examens.pas_reponse_qroc'));
            return;
          }
          answerRequest = this.httpChallengeSessionService.answerMatchQuestion(
            this.challengeSession.challenge.id,
            new AnswerMatchQuestionPayload(this.currentChallengeQuestion.question)
          );
          break;

        case QuestionAnswerMethod.ONE:
        case QuestionAnswerMethod.TCS:
          if (
            !this.currentChallengeQuestion.question.corrections.filter(
              (correction) => correction.isSelected
            ).length
          ) {
            this.toastr.warning(this.translate.instant('examens.pas_reponse'));
            return;
          }
          answerRequest = this.httpChallengeSessionService.answerOneOrTCSQuestion(
            this.challengeSession.challenge.id,
            new AnswerOneOrTCSQuestionPayload(this.currentChallengeQuestion.question)
          );
          break;
        case QuestionAnswerMethod.MANY_UNDEFINED:
          if (
            !this.currentChallengeQuestion.question.corrections.filter(
              (correction) => correction.isSelected
            ).length
          ) {
            this.toastr.warning(this.translate.instant('examens.pas_reponse'));
            return;
          }
          answerRequest = this.httpChallengeSessionService.answerManyUndefinedQuestion(
            this.challengeSession.challenge.id,
            new AnswerManyUndefinedQuestionPayload(this.currentChallengeQuestion.question)
          );
          break;
        case QuestionAnswerMethod.MANY_DEFINED_LONG:
        case QuestionAnswerMethod.MANY_DEFINED_SHORT:
          if (
            !this.currentChallengeQuestion.question.corrections.filter(
              (correction) => correction.isSelected
            ).length
          ) {
            this.toastr.warning(this.translate.instant('examens.pas_reponse'));
            return;
          }
          answerRequest = this.httpChallengeSessionService.answerManyDefinedQuestion(
            this.challengeSession.challenge.id,
            new AnswerManyDefinedQuestionPayload(this.currentChallengeQuestion.question)
          );
          break;
        case QuestionAnswerMethod.MATCHING_AREA:
          if (!this.currentChallengeQuestion.question.answerMatchingRecognition) {
            this.toastr.warning(this.translate.instant('examens.pas_reponse'));
            return;
          }
          answerRequest = this.httpChallengeSessionService.answerMatchingAreaQuestion(
            this.challengeSession.challenge.id,
            new AnswerMatchingAreaQuestionPayload(this.currentChallengeQuestion.question)
          );
          break;
      }

      if (answerRequest) {
        this.postAnswerToQuestion(answerRequest);
      }
    }
  }

  postAnswerToQuestion(answerRequest: Observable<AnswerToSessionQuestionResult>) {
    this.isAnswering = true;
    answerRequest
      .pipe(
        finalize(() => {
          this.isAnswering = false;
        })
      )
      .subscribe({
        next: (res) => {
          this.currentChallengeQuestion.question.isAnswered = true;

          this.googleAnalyticsService.sendEvent(
            'question answered',
            'custom-exam',
            this.challengeSession.challenge.id
          );

          this.grade = res.grade;
          this.maxGrade = res.maxGrade;
          this.currentChallengeQuestion.question.corrections.forEach((correction) => {
            const correspondingCorrectionResult = res.question.corrections.find(
              (c) => c.id === correction.id
            );
            correction.setCoordinates(correspondingCorrectionResult.coordinates);
            correction.setStatus(correspondingCorrectionResult.status);
          });

          this.launchAnimationsCorrection();

          setTimeout(() => {
            this.launchAnimationsExit()
              .pipe(take(1))
              .subscribe(() => {
                const modal = document.getElementById('modalCandidateChallenge');
                if (modal) {
                  supportedScroll(modal, 0);
                }
                this.goToNextQuestionEmitter.emit();
              });
          }, 3000);
        },
        error: (err) => {
          this.errorService.toastError(err);
        },
      });
  }

  launchAnimationsEntry() {
    timer(0)
      .pipe(
        concatMap(() => {
          this.animationState.questionTop = true;
          this.animationState.questionContent = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.questionNumber = true;
          return timer(100);
        }),
        concatMap(() => {
          this.animationState.questionText = true;
          return timer(500);
        }),
        concatMap(() => {
          this.animationState.questionAnswers = true;
          this.animationState.questionQroc = true;
          this.animationState.questionZap = true;
          return timer(100);
        }),
        concatMap(() => {
          this.animationState.questionButton = true;
          return timer(100);
        }),
        take(1)
      )
      .subscribe();
  }

  launchAnimationsCorrection() {
    this.soundService.isChallengeMusicActivated().subscribe((challengeMusicActivated) => {
      if (challengeMusicActivated) {
        if (this.grade === this.maxGrade) {
          this.soundService.playSound('challenge-right-answer');
        } else {
          this.soundService.playSound('challenge-wrong-answer');
        }
      }
    });

    this.isCorrection = true;

    if (
      this.currentChallengeQuestion.question.answerMethod === QuestionAnswerMethod.MATCHING_AREA
    ) {
      this.drawCorrection();
    }
  }

  launchAnimationsExit() {
    return timer(0).pipe(
      concatMap(() => {
        this.animationState.questionButton = false;
        return timer(100);
      }),
      concatMap(() => {
        this.animationState.questionAnswers = false;
        this.animationState.questionQroc = false;
        this.animationState.questionZap = false;
        return timer(100);
      }),
      concatMap(() => {
        this.animationState.questionText = false;
        return timer(100);
      }),
      concatMap(() => {
        this.animationState.questionNumber = false;
        return timer(100);
      }),
      concatMap(() => {
        this.animationState.questionTop = false;
        this.animationState.questionContent = false;
        return timer(100);
      }),
      take(1)
    );
  }

  initCanvas() {
    if (this.canvas && this.canvasContainer && this.canvas.nativeElement.getContext) {
      this.canvasContext = this.canvas.nativeElement.getContext('2d');
      this.resizeCanvas();
      this.drawPoint();
    }
  }

  resizeCanvas() {
    if (this.canvas && this.canvasContainer && this.canvas.nativeElement.getContext) {
      this.canvas.nativeElement.width = this.canvasContainer.nativeElement.clientWidth;
      this.canvas.nativeElement.height = this.canvasContainer.nativeElement.clientHeight;
    }
  }

  selectPoint(event: any) {
    const clickX = event.offsetX;
    const clickY = event.offsetY;
    const displayedImageWidth = this.imageZAP.nativeElement.clientWidth;
    const displayedImageHeight = this.imageZAP.nativeElement.clientHeight;
    const naturalImageWidth = this.imageZAP.nativeElement.naturalWidth;
    const naturalImageHeight = this.imageZAP.nativeElement.naturalHeight;
    const coordsImagePointX = (naturalImageWidth * clickX) / displayedImageWidth;
    const coordsImagePointY =
      naturalImageHeight - (naturalImageHeight * clickY) / displayedImageHeight;
    this.currentChallengeQuestion.question.answerMatchingRecognition = {
      x: Math.round(coordsImagePointX),
      y: Math.round(coordsImagePointY),
    };
    this.drawPoint();
  }

  drawPoint() {
    if (this.canvasContext) {
      const displayedImageWidth = this.imageZAP.nativeElement.clientWidth;
      const displayedImageHeight = this.imageZAP.nativeElement.clientHeight;
      const naturalImageWidth = this.imageZAP.nativeElement.naturalWidth;
      const naturalImageHeight = this.imageZAP.nativeElement.naturalHeight;
      this.canvasContext.clearRect(
        0,
        0,
        this.canvas.nativeElement.clientWidth,
        this.canvas.nativeElement.clientHeight
      );
      if (this.currentChallengeQuestion.question.answerMatchingRecognition) {
        this.canvasContext.fillStyle = '#2eff00';
        const pointX =
          (this.currentChallengeQuestion.question.answerMatchingRecognition.x *
            displayedImageWidth) /
          naturalImageWidth;
        const pointY =
          ((naturalImageHeight -
            this.currentChallengeQuestion.question.answerMatchingRecognition.y) *
            displayedImageHeight) /
          naturalImageHeight;
        this.canvasContext.fillRect(pointX - 4, pointY - 4, 8, 8);
      }
    }
  }

  drawCorrection() {
    if (this.canvasContext) {
      const displayedImageWidth = this.imageZAP.nativeElement.clientWidth;
      const displayedImageHeight = this.imageZAP.nativeElement.clientHeight;
      const naturalImageWidth = this.imageZAP.nativeElement.naturalWidth;
      const naturalImageHeight = this.imageZAP.nativeElement.naturalHeight;

      this.canvasContext.clearRect(
        0,
        0,
        this.canvas.nativeElement.clientWidth,
        this.canvas.nativeElement.clientHeight
      );

      const pointClicked = this.currentChallengeQuestion.question.answerMatchingRecognition;

      if (pointClicked) {
        this.canvasContext.fillStyle = this.grade === this.maxGrade ? '#2eff00' : '#fc4747';

        const pointX = (pointClicked.x * displayedImageWidth) / naturalImageWidth;
        const pointY =
          ((naturalImageHeight - pointClicked.y) * displayedImageHeight) / naturalImageHeight;
        this.canvasContext.fillRect(pointX - 4, pointY - 4, 8, 8);
      }

      this.canvasContext.fillStyle =
        this.grade === this.maxGrade ? 'rgba(46, 255, 0, 0.3)' : 'rgba(252, 71, 71, 0.3)';

      if (this.currentChallengeQuestion.question.corrections.length) {
        this.currentChallengeQuestion.question.corrections.forEach((correction) => {
          this.canvasContext.beginPath();
          correction.coordinates.forEach((point, idx) => {
            const pointX = (point.x * displayedImageWidth) / naturalImageWidth;
            const pointY =
              ((naturalImageHeight - point.y) * displayedImageHeight) / naturalImageHeight;

            if (idx === 0) {
              this.canvasContext.moveTo(pointX, pointY);
            } else {
              this.canvasContext.lineTo(pointX, pointY);
            }
          });

          this.canvasContext.closePath();
          this.canvasContext.fill();
        });
      }
    }
  }
}
