import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { QuestionAnswerMethod } from '../../shared/models/enums/question.enums';
import { FolderType } from '../../shared/models/enums/folder.enums';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor() {}

  isEcni() {
    if (environment.defaultLanguage === 'fr') {
      return true;
    } else {
      return false;
    }
  }

  isPrepSSM() {
    if (environment.defaultLanguage === 'it') {
      return true;
    } else {
      return false;
    }
  }

  isPrepMIR() {
    if (environment.defaultLanguage === 'es') {
      return true;
    } else {
      return false;
    }
  }

  get siteId() {
    return environment.site_id;
  }

  get siteThemeClass() {
    return `${environment.site_id}-theme-site`;
  }

  get googlePlayAppId() {
    return environment.google_play_id;
  }

  get googlePlayUrl() {
    return `https://play.google.com/store/apps/details?id=${this.googlePlayAppId}&hl=${environment.locale}`;
  }

  get appStoreAppId() {
    return environment.appstore_id;
  }

  get appStoreUrl() {
    return `https://itunes.apple.com/${environment.defaultLanguage}/app/ecni/${this.appStoreAppId}`;
  }

  get facebookUrl() {
    return environment.facebook_url;
  }

  get instagramUrl() {
    return environment.instagram_url;
  }

  get tiktokUrl() {
    return environment.tiktok_url;
  }

  get telegramUrl() {
    return environment.telegram_url;
  }

  get intercomHelpdesk() {
    return environment.intercomHelpdeskUrl;
  }

  get intercomEmail() {
    return `${environment.intercomAppId}@inbound.intercom-mail.com`;
  }

  get defaultLanguage() {
    return environment.defaultLanguage;
  }

  get siteName() {
    return environment.site_name;
  }

  get abbreviationsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get methodoEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get premiumButtonOnDashboard() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get showAllIsolatedQuestions() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get noAnswerAccepted() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get foldersEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get podcastsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get majorTestimoniesIsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  landingTestimony(section: string) {
    if (section === 'ancrage') {
      if (this.isEcni()) {
        // return {
        //   author: 'Charles-Meyer',
        //   text: '« Le mode <strong>ancrage des connaissances®</strong> aide également à organiser ses révisions autour de ses points faibles, ce qui constitue un atout majeur devant l’ampleur du programme. ! »',
        //   rank: 'Major aux ECNi 2019',
        // };
        return null;
      } else if (this.isPrepSSM()) {
        return {
          author: 'Nicola',
          text: `“Non avevo mai sentito parlare dell' <strong>ancoraggio mentale</strong>&nbsp;® ma da quando lo uso non ne posso più fare a meno: le mie capacità di ritenere informazioni a lungo termine sono migliorate grazie ai continui stimoli dell’algoritmo”`,
          rank: null,
        };
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'feedbacks') {
      if (this.isEcni()) {
        return {
          author: 'David',
          text: `« La base de donnée est gigantesque, et s'enrichit tout au long de l'année. Les classements sont bien représentatifs, et motivants. Le prix reste modeste en regard des bénéfices que l'on peut en tirer. Je m'y suis inscrit dès le début de la D4, et je ne le regrette pas! »`,
          rank: '3e aux ECNi 2019',
        };
      } else if (this.isPrepSSM()) {
        return {
          author: 'Elvis',
          text: `« Grazie ragazzi per l’opportunità gratuita che ci avete dato.Se ho vinto la borsa che desideravo è sicuramente anche grazie alle vostre domande,sicuramente abbastanza avanzate come difficoltà ma utili e commentate brillantemente,Consigliierei a chiunque questa piattaforma.Continuate così! Ancora grazie.»`,
          rank: null,
        };
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'ranking') {
      if (this.isEcni()) {
        return {
          author: 'Maxime',
          text: `“J’ai commencé à utiliser EDN durant ma D3. Un bon moyen de se familiariser avec les épreuves informatisées. Les concours blancs nationaux permettent de s’entraîner dans des conditions réalistes. Cela permet d’arriver le jour J plus rassuré”`,
          rank: 'Major aux ECNi 2018',
        };
      } else if (this.isPrepSSM()) {
        return {
          author: 'Greta',
          text: `« Ciao! Ci tenevo a ringraziarci per il lavoro che avete fatto.Ho fatto il concorso ed è andato molto bene, a novembre inizierò la specializzazione in ginecologia e ostetricia al Gemelli! I vostri quiz settimanali sono stati davvero importanti per la mia preparazione.Ho usufruito di altri siti che proponevano quiz per prepararsi ma il vostro è senza dubbio il migliore.Continuate così!.»`,
          rank: null,
        };
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'exams') {
      if (this.isEcni()) {
        return {
          author: 'Corentin',
          text: `« Probablement le meilleur site de préparation aux ECN niveau rapport qualité / quantité / prix. Un plus non négligeable pour bien aborder la D4 »`,
          rank: '24ème aux Ecni 2019',
        };
      } else if (this.isPrepSSM()) {
        return null;
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'conftube') {
      if (this.isEcni()) {
        return {
          author: 'Inès',
          text: `" Les Conftubes sont un format bien sympathique en cas de craquage, c'est sympa, c'est rigolo, et sans rire ça éclaire pas mal!"`,
          rank: '98ème aux ECNi 2019',
        };
      } else if (this.isPrepSSM()) {
        return null;
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'training') {
      if (this.isEcni()) {
        return {
          author: 'Simon',
          text: `"Je le recommande ++ notamment des la D2 ou la partie "training" permet de balayer chaque item un par un."`,
          rank: '29ème aux ECNi 2019',
        };
      } else if (this.isPrepSSM()) {
        return {
          author: 'Chiara',
          text: `"Ho trovato molto utile questa app per la preparazione di ssm19: i quiz sono ben fatti e ben spiegati, è utile capire quante persone hanno sbagliato una determinata domanda per capire se si hanno delle lacune importanti nella preparazione"`,
          rank: null,
        };
      } else if (this.isPrepMIR()) {
        return null;
      }
    }
  }

  get examsOnLanding() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get conftubeOnLanding() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get linkToPremiumInPublicTopNav() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get mindmapsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get lisaEnabled() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get pediatricEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get premiumEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get testimoniesEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get challengeEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get itemsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get coursesEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get linkToCoursesEnabled() {
    if (this.isEcni()) {
      return this.coursesEnabled && true;
    } else if (this.isPrepSSM()) {
      return this.coursesEnabled && false;
    } else if (this.isPrepMIR()) {
      return this.coursesEnabled && false;
    }
  }

  get trainingEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get multiRankEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get customExamEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get feedbacksEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get numbersOnLanding() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get showQuestionType() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get randomTestimonyOnModalPub() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get firstMultirankYear() {
    if (this.isEcni()) {
      return 2019;
    } else if (this.isPrepSSM()) {
      return 2019;
    } else if (this.isPrepMIR()) {
      return 2020;
    }
  }

  get supportAdditionalTimeInExam() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isNewFicheEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isR2CEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isReceiptsEnabled() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get isWriterBillsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get rankEcnEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get testimoniesDreamSpecialtyEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get examCustomMaxNbQuestions() {
    if (this.isEcni()) {
      return 140;
    } else if (this.isPrepSSM()) {
      return 140;
    } else if (this.isPrepMIR()) {
      return 250;
    }
  }

  get allowDownloadOnNewPdf() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get itemImageFirstInCoursesList() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get conftubeHasLogo() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get displayItemUpdatedAt() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isVideoAvailable() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get isVideoInBottomMenu() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get isUnitFolderAvailable() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get displaySubscriptionDetailsOnPricingPage() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get isEliteEnabled() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isEcosEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isParticipationNumbersEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get challengeMaxGrade() {
    if (this.isEcni()) {
      return 7;
    } else if (this.isPrepSSM()) {
      return 7;
    } else if (this.isPrepMIR()) {
      return 7 * 3;
    }
  }

  get isVideoRestrictedForElite() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get canWritersCreateFolders() {
    if (environment.defaultLanguage === 'fr') {
      return true;
    } else if (environment.defaultLanguage === 'it') {
      return true;
    } else if (environment.defaultLanguage === 'es') {
      return false;
    }
  }

  get socialSecurityNumberSize() {
    if (environment.defaultLanguage === 'fr') {
      return 15;
    } else if (environment.defaultLanguage === 'it') {
      return 16;
    } else if (environment.defaultLanguage === 'es') {
      return null;
    }
  }

  isFolderTypeEnabled(folderType: string) {
    if (folderType === FolderType.CLINICAL) {
      return this.isEcni() || this.isPrepSSM();
    } else if (folderType === FolderType.IMAGING) {
      return true;
    } else if (folderType === FolderType.LCA) {
      return this.isEcni();
    } else if (folderType === FolderType.KFP) {
      return this.isEcni();
    } else if (folderType === FolderType.TCS) {
      return this.isEcni();
    }
  }

  get preSelectQuestionAnswerMethod() {
    if (environment.defaultLanguage === 'fr') {
      return false;
    } else if (environment.defaultLanguage === 'it') {
      return true;
    } else if (environment.defaultLanguage === 'es') {
      return true;
    }
  }

  isQuestionAnswerMethodEnabled(answerMethod: string) {
    if (answerMethod === QuestionAnswerMethod.ONE) {
      return true;
    } else if (answerMethod === QuestionAnswerMethod.MANY_UNDEFINED) {
      return this.isEcni() || this.isPrepSSM();
    } else if (answerMethod === QuestionAnswerMethod.MANY_DEFINED_SHORT) {
      return this.isEcni();
    } else if (answerMethod === QuestionAnswerMethod.MANY_DEFINED_LONG) {
      return this.isEcni();
    } else if (answerMethod === QuestionAnswerMethod.MATCH) {
      return this.isEcni();
    } else if (answerMethod === QuestionAnswerMethod.MATCHING_AREA) {
      return this.isEcni();
    } else if (answerMethod === QuestionAnswerMethod.TCS) {
      return this.isEcni();
    }
  }
}
