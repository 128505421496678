import moment from 'moment';
import {
  AddCourseContentResult,
  CourseContentResult,
} from '../api-results/course-content.api-result';
import { CoursePictureResult } from '../api-results/course-picture.api-result';
import { CourseContentType } from '../enums/course-content.enums';
import {
  AddCourseContentPayload,
  CourseContentFormValue,
} from '../payloads/course-content.payload';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { environment } from '../../../../environments/environment';
import { Course } from './course.entity';

export class CourseContent {
  id: string;
  type: CourseContentType;
  name: string;
  title: string;
  year: number;
  priority: number;
  createdAt: Date;

  tabIndex: number;
  course: Course;

  constructor(result: CourseContentResult) {
    this.id = result.id;
    this.type = result.type;
    this.name = result.name;
    this.title = result.title;
    this.year = result.year;
    this.priority = result.priority;
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
  }

  toResult(): CourseContentResult {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      title: this.title,
      year: this.year,
      priority: this.priority,
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
    };
  }

  static addedEntityToResult(
    payload: AddCourseContentPayload,
    addedEntity: AddCourseContentResult
  ): CourseContentResult {
    return {
      id: addedEntity.id,
      type: payload.type,
      name: addedEntity.name,
      title: payload.title,
      year: payload.year,
      priority: payload.priority,
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
    };
  }
}
